var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1000,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单号",
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "orderCode",
                          {
                            rules: [
                              { required: true, message: "请输入订单号!" },
                            ],
                          },
                        ],
                        expression:
                          "['orderCode', {rules: [{ required: true, message: '请输入订单号!' }]}]",
                      },
                    ],
                    attrs: { placeholder: "请输入订单号" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单类型",
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["ctype", {}],
                          expression: "['ctype',{}]",
                        },
                      ],
                      attrs: { placeholder: "请输入订单类型" },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v("国内订单"),
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v("国际订单"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单日期",
                  },
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["orderDate", {}],
                        expression: "[ 'orderDate',{}]",
                      },
                    ],
                    attrs: { showTime: "", format: "YYYY-MM-DD HH:mm:ss" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单金额",
                  },
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["orderMoney", {}],
                        expression: "[ 'orderMoney', {}]",
                      },
                    ],
                    staticStyle: { width: "200px" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单备注",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["content", {}],
                        expression: "['content', {}]",
                      },
                    ],
                    attrs: { placeholder: "请输入订单备注" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }